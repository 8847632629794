.scrollable-modal-body__top,
.scrollable-modal-body__bottom {
    height: 8px;
    width: 100%;
}

.scrollable-modal-body_scroll-more-below {
    background: linear-gradient(to top, #999 0, #ffffff 100%);
}

.scrollable-modal-body_scroll-more-above {
    background: linear-gradient(to bottom, #999 0, #ffffff 100%);
}
