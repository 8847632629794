.nombre-lugar__text {
    position: absolute;
    width: 65%;
}

.nombre-lugar__imagen {
    background-position-y: 0;
    height: 94px;
    width: 326px;
    background-repeat: no-repeat;

    -webkit-transition: background-position 100ms ease-in;
    -moz-transition: background-position 100ms ease-in;
    -o-transition: background-position 100ms ease-in;
    transition: background-position 100ms ease-in;
}

.nombre-lugar__imagen_hide {
    background-position-y: -100px;
}
