.carrito-item__cantidad-col,
.carrito-item__precio-col {
    white-space: nowrap;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.carrito-item__precio-unitario {
    text-align: right;
    font-size: 8px;
}

.carrito-item__precio {
    text-align: right;
}

.carrito-item__modificador,
.carrito-item__comentarios {
    padding-left: 1rem;
    font-size: 10px;
}

.carrito-item__cantidad {
    padding-left: 2px;
    padding-right: 2px;
}

.carrito__footer-right {
    text-align: right;
}

@media (max-width: 400px) {
    .carrito-item__botones {
        display: block;
    }
}

@media (min-width: 400px) {
    .carrito-item__botones {
        padding-left: 1rem;
    }
}