.scroll__indicator {
    z-index: 11;
    width: 100%;
    height: var(--scroll__indicator__height);
    background: linear-gradient(to bottom, lightgray 0, #ffffff 100%);
}

.scroll__progressbar {
    height: var(--scroll__indicator__height);
    background: linear-gradient(to bottom, gray 0, #ffffff 100%);
    width: 0;
}

