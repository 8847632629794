.articulo-modal__modificador {
    border: black 1px solid;
}

.articulo-modal__modificador__title {
    font-weight: bold;
    margin-bottom: 0;
}

.articulo-modal__modificador__title_invalid {
    color: red;
}

.articulo-modal__modificador__checkbox,
.articulo-modal__modificador__radio {
    padding-left: 40px;
}

.articulo-modal__modificador-cantidad {
    text-align: center;
    height: 25px;
    align-self: flex-end;
}
