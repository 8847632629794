.carrito-boton {
    padding-left: 16px;
    white-space: nowrap;

}

.carrito-hover:hover {
    cursor: pointer;
}

.carrito-boton__icono {
    height: 50px;
    width: 50px;
}

.carrito-boton__cantidad {
    position: relative;
    border: 1px black solid;
    border-radius: 10px;
    background-color: lightblue;
    padding: 3px;
    display: inline-block;

    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);

    -webkit-transition: 500ms ease-in;
    -moz-transition: 500ms ease-in;
    -o-transition: 500ms ease-in;
    transition: 500ms ease-in;
}

.carrito-boton__cantidad_hide {
    -webkit-transform: translate(0, -300%);
    -moz-transform: translate(0, -300%);
    -o-transform: translate(0, -300%);
    transform: translate(0, -300%);
}

.carrito-mesa {
    width: 100%;
    text-align: center;
}