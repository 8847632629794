.division {
    padding-bottom: 20px;
    margin-top: 20px;
}

.division_collapse {
    padding-bottom: 0;
    border-bottom: 1px dotted black;
}

.division__nombre {
    font-weight: 700;
    font-size: 20px;
    padding-top: 20px;
}

.division__header_activa {
    animation: division__header_activa_frames ease-in 1s;
}

.division__header_activa-collapsed {
    animation: division__header_activa-collapsed_frames ease-in 1s;
}


@keyframes division__header_activa_frames {
    0% {
        transform: rotate(1deg);
    }
    25% {
        transform: rotate(-1deg);
    }
    50% {
        transform: rotate(1.5deg);
    }
    75% {
        transform: rotate(-2deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes division__header_activa-collapsed_frames {
    0% {
        transform: rotate(1deg);
    }
    25% {
        transform: rotate(-2deg);
    }
    50% {
        transform: rotate(1.5deg);
    }
    75% {
        transform: rotate(-1deg);
    }
    100% {
        transform: rotate(0deg);
    }
}


.division__descripcion {
    font-size: 15px;
    padding-bottom: 20px;
}

.division__imagen {
    background-repeat: no-repeat;
    width: 100%;
    max-width: 100%;
    min-height: 60px;
    max-height: 100%;
    margin-bottom: 15px;
}

.division-header {
    background-color: lightblue;
    z-index: 9;
}

.division:not(.division_collapse) > .division-header {
    position: sticky;
    top: calc(var(--header_short__height) + var(--scroll__indicator__height));
}

.division__nombre {
    padding-top: 0;
}

.division-header svg {
    height: 30px;
}
