.header {
    height: var(--header_long__height);
    transition: height 100ms ease-in;
}

.header_short {
    height: var(--header_short__height);
}

.header_long {
    box-shadow: 15px 0 10px black;
}

.header__scroll {
    position: fixed;
    top: var(--header_short__height);
    width: 100%;
    z-index: 10;
}