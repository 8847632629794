.articulo:hover {
    cursor: pointer;
}

.articulo__row {
    border-top: black 1px dotted;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: -10px;
    margin-right: -10px;
    max-height: 100%;
}

.articulo__imagen {
    width: 50px;
    height: 50px;
}

.articulo__nombre-col {
    flex-grow: 3;
}

.articulo:hover .articulo__nombre-col {
    cursor: pointer;
    background: lightgray;
}


.articulo__nombre {
    font-size: 16px;
}

.articulo__descripcion {
    font-size: 10px;
}

.articulo__precio {
    float: right;
    text-align: right;
    font-weight: 700;
}

.articulo__precio-desactivado {
    float: right;
    text-align: right;
    font-size: 10px;
}