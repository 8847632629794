.enviando__header {
    font-weight: bold;
}

.enviando__error__header {
    font-weight: bold;
    background: red;
}

.enviando__success__header {
    font-weight: bold;
    background: green;
}

.enviando-spinner.spinner-border {
    width: 10rem;
    height: 10rem;
}