.catalogo__main {
    padding-top: var(--header_long__height);
}

.h-100-center {
    margin-top: auto;
    margin-bottom: auto;
}

.catalogo-spinner.spinner-border {
    width: 10rem;
    height: 10rem;
}