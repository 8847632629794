.articulo-modal__imagen {
    width: 100px;
    height: 100px;
}

.articulo-modal__descripcion {
    padding-left: 15px;
    font-size: 14px;
}

.articulo-modal__cantidad-line {
    display: flex;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.articulo-modal__cantidad-button {
    height: 25px;
    width: 25px;
}

.articulo-modal__cantidad-button_disabled {
    color: transparent;
}

.articulo-modal__cantidad {
    text-align: center;
    height: 25px;
    align-self: flex-end;
}

.articulo-modal__cantidad_invalid {
    background-color: red;
}

.articulo-modal__footer-right {
    text-align: right;
}

.articulo-modal__comentarios__title {
    font-weight: bold;
}

.articulo-modal__form {
    margin-top: 10px;
}

.articulo-modal__precio {
    text-align: right;
    padding-top: 10px;
}
